@import 'node_modules/reset.css';
@import 'src/include';
@import 'scroll-bar-styling';
@import './scale';

@import '../assets/commissioner-webfont/style.css';
@import '../assets/jet-brains-mono-webfont/style.css';

* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;

  font-family: Commissioner, sans-serif;

  // font-family: sans-serif;
  font-feature-settings: 'lnum';

  background-color: $colorBackground;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  background-color: transparent;

  &:disabled {
    filter: grayscale(100%);
  }

  ::-moz-focus-inner {
    border: 0;
  }
}

.hideOnPhone {
  @media #{$phone} {
    display: none;
  }
}

.hideOnMobilePhone {
  @media #{$mobile-phone} {
    display: none;
  }
}

.hideOnTabletAndDesktop {
  @media #{$tablet-and-desktop} {
    display: none;
  }
}

.visibleOnDesktopAndTablet {
  @media #{$phone} {
    display: none;
  }
}

.visibleOnPhone {
  @media #{$tablet-and-desktop} {
    display: none;
  }
}

.visibleOnTablet {
  @media #{$tablet-and-desktop} {
    display: none;
  }
  @media #{$mobile-phone} {
    display: none;
  }
}

.visibleOnMobilePhone {
  @media #{$mobile-phone-and-desktop} {
    display: none;
  }
}

.sectionTitle {
  font-family: JetBrains Mono, sans-serif;
  font-weight: 400;
  font-size: 60px;
  line-height: 60px;
  margin-bottom: 50px;
  @media #{$mobile-phone} {
    font-size: 36px;
  }
}
