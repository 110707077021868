@import 'styles/media';

$colorAccent: #247CFF;
$colorBackground: #E9FAFF; //REMOVED AFTER DESIGN CHANGES
$colorGrey: #627384;
$colorDark: #050505;
$colorWhite: #FFFFFF;


%content-container {
  $width: 1320px;
  $paddingHorizontal: 60px;

  box-sizing: border-box;
  max-width: $width + ($paddingHorizontal * 2);
  margin-right: auto;
  margin-left: auto;
  padding: 0 $paddingHorizontal;

  @media #{$phone} {
    $width: 100%;
    $paddingHorizontal: 60px;

    box-sizing: border-box;
    max-width: 100%;
    padding: 0 $paddingHorizontal;
  }

  @media #{$mobile-phone} {
    $paddingHorizontal: 30px;
    padding: 0 $paddingHorizontal;
  }
}
