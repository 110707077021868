@font-face {
  font-family: Commissioner;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("./commissioner-v20-cyrillic_cyrillic-ext_latin-300.woff2")
    format("woff2");
}

@font-face {
  font-family: Commissioner;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./commissioner-v20-cyrillic_cyrillic-ext_latin-regular.woff2")
    format("woff2");
}

@font-face {
  font-family: Commissioner;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("./commissioner-v20-cyrillic_cyrillic-ext_latin-500.woff2")
    format("woff2");
}

@font-face {
  font-family: Commissioner;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("./commissioner-v20-cyrillic_cyrillic-ext_latin-700.woff2")
    format("woff2");
}
