@font-face {
  font-family: "JetBrains Mono";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("./jetbrains-mono-v18-cyrillic_cyrillic-ext_latin-300.woff2")
    format("woff2");
}

@font-face {
  font-family: "JetBrains Mono";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./jetbrains-mono-v18-cyrillic_cyrillic-ext_latin-regular.woff2")
    format("woff2");
}
